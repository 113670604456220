import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import ErrorIcon from '@material-ui/icons/Error';

export default function Forbidden() {
    const [show, setShow] = useState(true)
    const handleClosedel = () => {
        setShow(false)
        window.location.assign('https://secure.phongsavanhgroup.com/');
    }
    // useEffect(()=>{
    //     window.location.assign('https://secure.phongsavanhgroup.com/');
    // },[])
    return (
        <div>
            <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} >
                <Modal.Header closeButton>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginRight: 20 }}>
                        <ErrorIcon style={{ fontSize: 45, fontWeight: 'bold', borderColor: '#0d6efd', color: '#0d6efd' }} />
                        <h2 style={{ marginLeft: 20,marginTop:5 }}>403 Forbidden</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <small style={{fontSize:20,fontWeight:'bold'}}> You do not have required permissions to perform the action.</small>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )

}