import axios from 'axios';
import { httpErrorHandler } from './middleware/httpErrorHandler';

export default function setAuthToken(token,setCheckConditions) {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // You can also make a request to verify the token here if needed
        // For example:
        // await axios.get('/api/verify-token');
      } else {
        delete axios.defaults.headers.common['Authorization'];
      }
    } catch (error) {
      httpErrorHandler(error,setCheckConditions)
    }
  }