import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    // Check if the token is still valid before making the request
    let users = Cookies.get("user");
    let data = JSON.parse(users)
    const token = data.token; // Get the token from wherever you store it
    if (token) {
      // You can perform additional token validation logic here
      // For example, you might want to check the token expiration date

      // Attach the token to the request headers
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle the response data
    return response;
  },
  (error) => {
    // Handle errors
    if (error.response && error.response.status === 401) {
      // Token is invalid or expired
      // You can redirect the user to the login page or refresh the token here
      console.error('Token is invalid or expired');
    }else if (error.response && error.response.status === 404) {
        console.error('Token is invalid or expired')
    }else if(error.response && error.response.status === 403){
      console.log("Token is invalid or expired 403")
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;